//import jQuery
import jQuery from 'jquery';
//bootstrap
import 'bootstrap';
//moment
import moment from 'moment';
//apexcharts
import ApexCharts from 'apexcharts';
//metisMenu
import metisMenu from 'metismenu';
//axios
import axios from 'axios';
//jsqr
import jsQR from 'jsqr';
//dragula
import dragula from 'dragula';



window.$ = jQuery;
window.jQuery = jQuery;
window.ApexCharts = ApexCharts;
window.moment = moment;
window.axios = axios;
window.metisMenu = metisMenu;
window.jsQR = jsQR;
window.dragula = dragula;